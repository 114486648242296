<template>
  <fragment>

    <div class="row pt-5 category-list" v-if="categories.length >= 0">
      <div class="col-xs-12 d-flex pt-3" style=" align-items: center; flex-direction: column; ">
        <span class="badge badge-info shadow-sm m-1" v-bind:class="BindOrderStatus('my')" @click="getCursos(); setCategory('my', 'my')">
          <h4>Meus cursos</h4>
        </span>
        <button class="badge badge-primary shadow-sm m-1" @click="ty = 'externo'; categoria= 'all'; getCursosExtern();">
          <h4>Cursos externos</h4>
        </button>
      </div>
      <!--<div class="col pt-3">
      <span class="badge badge-info shadow-sm" v-bind:class="BindOrderStatus('all')" @click="setCategory('all','all')">Todos cursos ofertados</span>
    </div>-->
      <!-- 
    <div class="col pt-3" v-for="cat in categories" :key="cat.slug">
      <span
        class="badge badge-info shadow-sm"
        v-bind:class="BindOrderStatus(cat.slug)"
        v-bind:id="cat.slug"
      @click="setCategory(cat.slug, 'all')"
      >{{cat.name}}</span>
    </div>
  -->
      <div class="col pt-3" v-for="cat in categories">
        <div class="text-center" v-bind:class="BindOrderStatus(cat.slug)" v-bind:id="cat.slug"
          @click="setCategory(cat.slug, 'all')" v-if="cat.icone != undefined">
          <img :src="$apiURL + cat.icone"
            style="width: 90px;background-color: var(--primary);border-radius: 50%;padding: 10px;cursor: pointer;border: 2px solid #0e0e0e;">
          <br>
          <small style=" font-size: 9pt; ">{{ cat.nome }}</small>
        </div>
        <div class="text-center btn btn-sm btn-primary shadow-sm mt-3 my_link" @click="setCategory(cat.slug, 'all')" v-else>
          {{ cat.nome }}
        </div>
      </div>


    </div>
    <div v-if="type == 'my'">
      <div class="row" v-if="ty == 'my' || ty == 'all'">
        <div class="col pt-5 d-md-flex d-inline text-center justify-content-between">
          <h2 id="titleDescr">Meus cursos ativos</h2>
          <router-link :to="{ name: 'addcurso' }" class="btn btn-tertiary mt-3 mt-md-0">+ Publicar novo
            curso</router-link>
        </div>
      </div>
      <transition-group mode="out-in" tag="div" class="row" v-if="cursos.length >= 0 && ty == 'my' || ty == 'all'">
        <div class="col-md-4 pt-4" v-for="(curso) in cursos" :key="curso._id">
          <router-link :to="{ name: 'cursoprofessor', params: { id: curso._id } }">
            <div class="card product shadow-sm border-0 d-flex justify-content-start">
              <!-- <div class="card-header"></div> -->
              <div class="card-header" :style="'background: url(' + $storage + curso.cover_url + ');'">
                <span class="badge badge-success" v-if="curso.status">Ativo</span>
                <span class="badge badge-danger" v-else>Inativo</span>
                <span class="badge badge-success float-right" v-if="curso.destaque">Destaque</span>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center pb-0">
                  <p class="small secondary">{{ curso.nome }}</p>
                  <p class="d-flex align-items-center">
                    <i class="material-icons rate">star_rate</i>
                    {{ curso.total }}
                  </p>
                </div>

                <p class="card-title">{{ curso.titulo }}</p>
                <p class="small secondary">{{ curso.categoria }}</p>
                <small class="text-muted">{{ !curso.descricao ? '' : curso.descricao }}</small>
              </div>
              <div class="card-footer border-0 bg-white pb-0">
                <div class="d-flex justify-content-between align-items-center pb-0">
                  <p class="d-flex flex-column align-items-start small" v-if="$wlType === 'pdv'">
                    <small class="danger preco-promo" v-if="parseInt(curso.desconto) > 0">{{ curso.valor |
                      currency }}</small>
                    <span class="lead" v-if="parseInt(curso.desconto) > 0 && parseInt(curso.valor) > 0">{{ curso.desconto |
                      currency }}</span>
                    <span class="lead" v-else>Curso Grátis</span>
                  </p>

                  <p class="d-flex align-items-center small" v-if="$wlType === 'uc'">
                    <i class="material-icons tertiary">list</i> {{ curso.modulos }} módulos
                  </p>
                  <p class="d-flex align-items-center small">
                    <i class="material-icons tertiary">schedule</i>
                    {{ curso.carga }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </transition-group>
      <div v-if="newcourses.length == 0 && ty == 'my'">
        <loading color="var(--secondary)" v-if="loading" />
        <div class="card border-0 mt-4" v-else>
          <div class="card-body">
            <h5 class="d-flex justify-content-center align-items-center">
              <i class="material-icons pr-2 py-3 blue tertiary">info</i> Você ainda não publicou nenhum curso.
            </h5>
          </div>
        </div>
      </div>
      <hr>
      <div class="row" v-if="newcourses.length >= 0 && ty == 'externo' || ty == 'all'">
        <div class="col pt-5 d-md-flex d-inline text-center justify-content-between">
          <h2 id="titleDescr">Cursos externos</h2>
          <router-link :to="{ name: 'adquiridos' }" class="btn btn-tertiary mt-3 mt-md-0 d-flex"><span class="material-icons" >view_list</span> Cursos adquiridos</router-link>
          <router-link :to="{ name: 'share' }" class="btn btn-tertiary mt-3 mt-md-0">+ Amplie portfólio</router-link>
        </div>
      </div>
      <transition-group mode="out-in" tag="div" class="row"
        v-if="newcourses.length >= 0 && ty == 'externo' || ty == 'all'">
        <div class="col-md-4 pt-4" v-for="(newcurso) in newcourses" :key="newcurso._id">
          <router-link :to="{ name: 'curso', params: { id: newcurso.slug } }">
            <div class="card product shadow-sm border-0 d-flex justify-content-start">
              <!-- <div class="card-header"></div> -->
              <div class="card-header" :style="'background: url(' + newcurso.cover_url + ');'">
                <span class="badge badge-success" v-if="newcurso.ativo">Ativo</span>
                <span class="badge badge-danger" v-else>Inativo</span>
                <span class="badge badge-info float-right">Curso Externo</span>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center pb-0">
                  <!--<p class="small secondary">{{ newcurso.titulo }}</p>-->
                  <!--
              <p class="d-flex align-items-center">
                <i class="material-icons rate">star_rate</i>
                {{newcurso.total}}
              </p>
              -->
                </div>

                <p class="card-title">{{ newcurso.titulo }}</p>

                <p class="small secondary" v-if="newcurso.newcategoria">{{ newcurso.newcategoria.nome }}</p>

                <p class="small secondary" v-else>{{ newcurso.categoria.nome }}</p>
                
                <small class="text-muted">{{ !newcurso.descricao ? '' : newcurso.descricao }}</small>
              </div>
              <div class="card-footer border-0 bg-white pb-0">
                <div class="d-flex justify-content-between align-items-center pb-0">
                  <p class="d-flex flex-column align-items-start small" v-if="$wlType === 'pdv'">
                    <small class="danger preco-promo" v-if="parseInt(newcurso.valor_desconto) > 0">{{ newcurso.valor |
                      currency }}</small>
                    <span class="lead"
                      v-if="parseInt(newcurso.valor_desconto) > 0 && parseInt(newcurso.valor) > 0">{{ newcurso.valor_desconto
                        | currency }}</span>
                    <span class="lead" v-else>Curso Grátis</span>
                  </p>

                  <p class="d-flex align-items-center small" v-if="$wlType === 'uc'">
                    <i class="material-icons tertiary">list</i> {{ newcurso.modulos.length }} módulos
                  </p>
                  <p class="d-flex align-items-center small">
                    <i class="material-icons tertiary">schedule</i>
                    {{ newcurso.carga }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </transition-group>
    </div>
    
    <div v-else>
      <transition-group mode="out-in" tag="div" class="row" v-if="cursos.length >= 0">
        <div class="col-md-4 pt-4" v-for="(curso, index) in cursos" :key="`key-${index}`">
          <!--<router-link :to="{ name: 'curso', params: { id: curso.slug } }">-->
          <router-link :to="{ name: 'curso', params: { id: curso.ref_id } }">
            <div class="card product shadow-sm border-0 d-flex justify-content-start">
              <!-- <div class="card-header"></div> -->
              <!--<div class="card-header" :style="'background: url(' + $storage + curso.capa + ');'"></div>-->
              <div class="card-header" :style="'background: url(' + curso.cover_url + ');'"></div>
              <div class="card-body">
                <!--<p class="small secondary">{{curso.category.nome}}</p>-->
                <div class="d-flex justify-content-between align-items-center pb-0">
                  <p class="d-flex align-items-center">
                    <Rating class="pt-2" v-model="curso.total" :cancel="false" :disabled="true" />
                  </p>
                </div>

                <p class="card-title mb-2">{{ curso.title }}</p>
                <p class="small secondary">{{ curso.category.nome }}</p>
                <small class="text-muted">{{ !curso.description ? '' : curso.description }}</small>
              </div>
              <div class="card-footer border-0 bg-white pb-0">
                <div class="d-flex justify-content-between align-items-center pb-0">
                  <p class="d-flex flex-column align-items-start small" v-if="$wlType === 'pdv'">
                    <small class="danger preco-promo"
                      v-if="parseInt(curso.promotional_value) > 0 || parseInt(curso.value) > 0">{{ curso.value |
                        currency }}</small>
                    <span class="lead" v-if="parseInt(curso.promotional_value) > 0">{{ curso.promotional_value |
                      currency }}</span>
                    <span class="lead" v-else>Curso Grátis</span>
                  </p>

                  <p class="d-flex align-items-center small" v-if="$wlType === 'uc'">
                    <i class="material-icons tertiary">list</i> {{ curso.modules.length }} módulos
                  </p>
                  <p class="d-flex align-items-center small">
                    <i class="material-icons tertiary">schedule</i>
                    {{ curso.workload }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </transition-group>
      <div v-else>
        <div>
          <loading color="var(--secondary)" />
        </div>
      </div>

      <div v-if="loadCurso == true">
        <loading color="var(--secondary)" />
      </div>
      <div class="row d-flex justify-content-center align-items-center pt-3" v-else>
        <div class="card border-0">
          <div class="card-body">
            <h5 class="d-flex justify-content-center align-items-center">
              <i class="material-icons pr-2 py-3 blue tertiary">info</i> Não tem mais cursos.
            </h5>
          </div>
        </div>
      </div>
    </div>

  </fragment>
</template>

<script>
import Rating from "primevue/rating";
export default {
  name: "CursosProfessor",
  components: { Rating },
  props: {
    role: String,
  },
  data() {
    return {
      loading: false,
      categoria: 'my',
      type: 'my',//my || all
      ty: 'all',
      cursos: [],
      cursosAluno: [],
      page: 0,
      scro: true,
      loadCurso: true,
      Mycursos: [],
      categories: [],
      DataCategory: [],
      DataCourse: [],
      slug: '',
      newcourses: [],
    };
  },
  filters: {
    currency(valor) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(valor);
    },
  },
  methods: {
    async getCursos() {
      //this.loadCurso = true
      this.cursos = [];
      this.$http.get(`/api/cursos/all`).then((response) => {
        this.cursos = response.data.entries;

        //this.loadCurso = false
      })
        .catch(error => {
          console.log(error)
        })
    },
    async getCursosExtern() {
      this.loadCurso = true
      this.newcourses = [];
      this.$http.get(`/api/gestor/newcourses`).then((response) => {
        if (this.categoria == 'all' || this.categoria == 'my') {
          this.newcourses = response.data;
        }else{
          this.newcourses = response.data.filter(cours => cours.newcategoria.slug == this.categoria || cours.categoria.slug == this.categoria);
        }
        this.ty = 'all';
        this.type = 'my';        
        this.loading = false
      })
        .catch(error => {
          console.log(error)
        })
    },
    getCursosAll() {
      this.page = 0;
      this.cursos = [];
      const headers = { "wlToken": this.$wlToken };
      var paramsList;
      if (this.categoria == 'all') {
        paramsList = { limit: 100/*limit: 9*/, offset: this.page };
      } else {
        paramsList = { category: this.categoria, limit: 9, offset: this.page };
      }
      var params = '?' + new URLSearchParams(paramsList);
      fetch(this.$apiHq + "/course/list" + params, { headers })
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            data.filter(result => {
              if (result.ref_token != this.$wlToken) {
                if (this.DataCourse.length > 0 || this.DataCategory.length > 0) {
                  if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                    if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                      this.cursos.push(result);
                    }
                  }
                } else {
                  this.cursos.push(result);
                }
              } else {
                this.cursos.push(result);
              }
            })

            //this.cursos = dataCursos;
            this.scro = true;
            if (data.length < 9) {
              //this.loadCurso = false;
            }
          } else {
            this.scro = false;
            //this.loadCurso = false;
          }
        });
    },
    BindOrderStatus: function (slug) {
      //if(slug == this.$route.query.categoria)
      if (slug == this.categoria) {
        return " active disabled'";
      }
    },
    setCategory(slug, type) {
      this.ty = 'all';
      this.cursos = [];
      //this.type = type;
      this.type = 'all';
      this.categoria = slug;
      this.page = 0;
      if (type == 'all') {
        this.scro = true;
        this.loadCurso = true;
        this.getCursosAll();
        this.getCursosExtern();
      } else {
        this.getCursos();
        this.getCursosExtern();
      }
      if (type == 'all') {
        if(document.getElementById('titleDescr') != null){
          document.getElementById('titleDescr').innerText = 'Todos cursos ofertados'; 
        }
      } else {
        if(document.getElementById('titleDescr') != null){
          document.getElementById('titleDescr').innerText = 'Meus cursos';
        }
      }
    },
    async getCategoriasRemov() {
      this.DataCategory = [];
      this.$http
        .get("/api/user/actionCategory")
        .then((response) => {
          this.DataCategory = response.data;
          this.getCategorias();
        }).catch(() => {
          this.getCategorias();
        });
    },
    async getCursosRemov() {
      this.DataCourse = [];
      this.$http
        .get("/api/user/actionCourse")
        .then((response) => {
          this.DataCourse = response.data;
        }).catch(() => {
        });
    },
    async getCategorias() {
      this.categories = [];
      const headers = { "wlToken": this.$wlToken };
      fetch(this.$apiHq + "/category/list", { headers })
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            data = data.sort(function (a, b) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });
            var anl = true;
            if (this.DataCategory.length > 0) {
              data.filter(result => {

                if (result.ids.length > 0) {
                  anl = true;
                  for (var e = 0; e < result.ids.length; e++) {
                    if (result.ids[e].ref_token == this.$wlToken) {
                      anl = false;
                    }
                  }
                }
                if (anl) {
                  //retur categorias com status true;
                  if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.slug) < 0) {
                    this.categories.push(result)
                  }
                } else {
                  this.categories.push(result)
                }
              });
            } else {
              this.categories = data;
            }
          }
        }).catch(() => {});
    },
	getCategoriasAll() {
      this.$http.get("/api/gestor/categorias").then((response) => {
        this.categories = response.data;
      });
    },
    handleScroll: function () {
      if (this.scro == true && this.type == 'all') {
        if (document.documentElement.scrollTop >= (document.documentElement.scrollHeight - (document.documentElement.scrollHeight * 70) / 100) &&
          document.documentElement.scrollTop > 450) {
          this.scro = false;
          this.page += 9;
          const headers = { "wlToken": this.$wlToken };
          var paramsList;
          if (this.categoria == 'all') {
            paramsList = { limit: 9, offset: this.page };
          } else {
            paramsList = { category: this.categoria, limit: 9, offset: this.page };
          }
          var params = '?' + new URLSearchParams(paramsList);
          fetch(this.$apiHq + "/course/list" + params, { headers })
            .then(response => response.json())
            .then(data => {
              if (data.length > 0) {
                data.filter(result => {
                  if (result.ref_token != this.$wlToken) {
                    if (this.DataCourse.length > 0 || this.DataCategory.length > 0) {
                      if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                        if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                          this.cursos.push(result);
                        }
                      }
                    } else {
                      this.cursos.push(result);
                    }
                  } else {
                    this.cursos.push(result);
                  }
                })
                this.scro = true;
              } else {
                this.scro = false;
                //this.loadCurso = false;
              }
            });
        }
      }
    }
  },
  mounted() {
    //this.getCursosRemov();
    this.getCategoriasRemov();
    this.getCursos();
    this.getCursosExtern();
	this.getCategoriasAll();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
.text-muted {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 900px) {
  .category-list {
    width: 100vw;
    overflow-y: hidden;
    white-space: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.category-list {
  .badge {
    min-width: 100px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background: var(--white);
    color: var(--dark-2);
    cursor: pointer;

    &:hover {
      background: var(--tertiary);
      color: var(--white);
    }
  }

  .badge.active {
    background: var(--tertiary);
    color: var(--white);
  }
}
</style>