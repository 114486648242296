import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=fb8bb4bc&scoped=true"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb8bb4bc",
  null
  
)

export default component.exports